/* eslint-disable no-unused-expressions */
import { required, url, helpers } from 'vuelidate/lib/validators';

// eslint-disable-next-line no-unused-vars
const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);

// eslint-disable-next-line no-unused-vars
const mustBeValid = (value, x) => {
	if (x.csiSmtId === 6) {
		return phoneCheck(value);
	}

	return url(value);
	// return url(value);
};

const socialMediaValidationMixin = {
	validations: {
		cmsSocialMedia: {
			$each: {
				csiLink: {
					mustBeValid,
					required,
				},
				csiSmtId: {
					required,
				},
				csiIsFeatured: {},
				csiLocation: {},
			},
		},
	},
	methods: {
		// validation methods for this page
		csiLinkErrors(index) {
			const errors = [];
			const { $dirty, required, mustBeValid } = this.$v.cmsSocialMedia.$each[index].csiLink;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir web adres giriniz.');
			!mustBeValid && errors.push('Geçerli bir web adresi giriniz.');
			return errors;
		},
		csiPhoneErrors(index) {
			const errors = [];
			const { $dirty, required, mustBeValid } = this.$v.cmsSocialMedia.$each[index].csiLink;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir telefon numarası giriniz.');
			!mustBeValid && errors.push('Geçerli bir telefon numarası giriniz.');
			return errors;
		},
	},
};

export default socialMediaValidationMixin;

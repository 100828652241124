<template>
	<v-card flat v-if="initialized">
		<v-card-title>
			Sosyal Medya Hesapları<v-spacer></v-spacer>
			<v-btn depressed class="text-capitalize" @click="newSocial">
				<v-icon left x-small v-text="'fas fa-plus'"></v-icon>
				yeni
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-list dense>
				<v-list-item dense class="px-0 v-i" v-if="cmsSocialMedia.length === 0">
					<v-list-item-content class="justify-center text-center">
						Henüz bir sosyal medya hesabı eklenmemiş.
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					dense
					class="px-0"
					v-for="(social, index) in $v.cmsSocialMedia.$each.$iter"
					:key="`social-${index}`"
				>
					<v-list-item-content class="d-flex flex-row">
						<v-row>
							<v-col cols="12" md="4">
								<v-select
									outlined
									label="Sosyal Medya Hesabı"
									item-text="smtName"
									item-value="smtId"
									:items="socialMedias"
									:return-object="false"
									hide-details="auto"
									dense
									color="teal"
									v-model="social.csiSmtId.$model"
								>
									<template v-slot:item="{ item, attrs, on }">
										<v-list-item v-on="on" v-bind="attrs" color="teal" active-class="teal lighten-5">
											<v-list-item-icon class="mx-0">
												<v-icon x-small v-text="item.smtIcon" :style="getSocialColor(item)"></v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												{{ item.smtName }}
											</v-list-item-content>
										</v-list-item>
									</template>
								</v-select>
							</v-col>
							<v-col cols="12" md="5">
								<v-text-field
									v-if="social.$model.csiSmtId !== 6"
									label="Sosyal Medya Adresi"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="social.csiLink.$model"
									:error-messages="csiLinkErrors(index)"
								></v-text-field>
								<v-text-field-simplemask
									v-else
									v-model="social.csiLink.$model"
									v-bind:label="'Cep Telefon Numarası'"
									v-bind:properties="{
										color: 'teal',
										inputmode: 'numeric',
										prefix: '+90',
										placeholder: '(555)-555-55-55',
										dense: true,
										outlined: true,
										errorMessages: csiPhoneErrors(index),
										hideDetails: 'auto',
										flat: true,
									}"
									v-bind:options="{
										inputMask: '(###)-###-##-##',
										outputMask: '##########',
										empty: null,
										applyAfter: false,
										alphanumeric: false,
										lowerCase: false,
									}"
								/>
							</v-col>
							<v-col cols="12" md="1">
								<v-checkbox
									class="pt-0 mt-0"
									v-model="social.csiIsFeatured.$model"
									label="Öne Çıkar"
									hide-details="auto"
									dense
									color="teal"
								></v-checkbox>
							</v-col>
							<v-col cols="12" md="2">
								<v-select
									outlined
									label="Sosyal Medya Ikon Konumu"
									item-text="name"
									item-value="id"
									:items="socialMediaIconLocations"
									:return-object="false"
									hide-details="auto"
									dense
									color="teal"
									v-model="social.csiLocation.$model"
								>
								</v-select>
							</v-col>
						</v-row>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn outlined color="red darken-4" depressed class="text-capitalize" @click="removeSocial(index)">
							<v-icon left x-small v-text="'fas fa-trash'"></v-icon>
							sil
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<div class="d-flex justify-start py-5">
				<v-btn
					:loading="savePreloader"
					color="teal"
					class="text-capitalize"
					:dark="!$v.cmsSocialMedia.$invalid"
					@click="save"
					:disabled="$v.cmsSocialMedia.$invalid"
				>
					Değişiklikleri Kaydet
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { FETCH_SITE_SETTINGS, UPDATE_SOCIAL_MEDIA } from '@/store/modules/company.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import socialMediaValidationMixin from '@/mixins/validations/backoffice/socialMediaValidationMixin';

export default {
	name: 'Social',
	mixins: [validationMixin, socialMediaValidationMixin],
	data() {
		return {
			savePreloader: false,
			cmsSocialMedia: [],
			socialMediaIconLocations: [
				{
					id: 1,
					name: 'Sağ Alt',
				},
				{
					id: 2,
					name: 'Sol Alt',
				},
			],
		};
	},

	computed: {
		...mapGetters(['settings', 'socialMedias']),
		initialized() {
			return this.cmsSocialMedia !== null;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateSocialMedia: UPDATE_SOCIAL_MEDIA,
		}),

		newSocial() {
			this.cmsSocialMedia.push({
				csiSmtId: null,
				csiLink: null,
				csiIsFeatured: false,
				csiLocation: 1,
			});
		},
		getSocialColor(social) {
			return {
				color: social.smtBackground,
			};
		},
		removeSocial(index) {
			this.cmsSocialMedia.splice(index, 1);
		},

		save() {
			this.savePreloader = true;
			this.updateSocialMedia(this.cmsSocialMedia)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
				})
				.finally(() => {
					this.savePreloader = false;
				});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				company.socialMedia.forEach((item, index) => {
					if (item.csiSmtId === 6 && (item.csiLink ?? '') !== '') {
						company.socialMedia[index].csiLink = item.csiLink.substring(2, item.csiLink.length);
					}
				});
				this.cmsSocialMedia = company.socialMedia;
			},
			deep: true,
		},
	},
};
</script>

<style></style>
